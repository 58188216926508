import React, { useEffect, useState, useContext, useRef } from "react";
import { useNavigate } from "react-router-dom";
import UserContext from "../../context/UserContext";
import { useDownloadExcel } from "react-export-table-to-excel";

import Axios from "axios";
import ErrorNotice from "../misc/ErrorNotice";

export default function CanonicalFilter() {
  const [canonical, setCanonical] = useState();
  const [comments, setComments] = useState([]);
  //const [error, setError] = useState();

  const { userData } = useContext(UserContext);
  const history = useNavigate();

  const tableRef = useRef(null);

  useEffect(() =>{
    if (localStorage.getItem("auth-token") === '') history('/admin/login');
  })

  const getComments = async (e) => {
    if (canonical) {
        fetch(`${process.env.REACT_APP_BACK_DOMAIN}/canonical-all/?canonical=${encodeURIComponent(canonical)}`,{     
            method:'GET',           
            headers:{
                'Accept':'application/json',
                'Content-Type': 'application/json',
                'x-access-token': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7ImlkIjoiY29tdW5pY2FuIn0sImlhdCI6MTU5NDQxNzQ1M30.WSWIJsVepunt6oVW-lywb_gw_ABRimLAQRRYxG1YzTM'
            }
        })
        .then((res) => res.json())
        .then((data) => {
          setComments(data);
        });
    }
    if (e) {
      e.preventDefault();
    }
  };

  const editComment = (id = false) => {
    // eslint-disable-next-line no-restricted-globals
    if (confirm("¿Desea cambiar el estado de este comentario?")) {
      fetch(`${process.env.REACT_APP_BACK_DOMAIN}/delete/${id}/${userData.user.displayName}`, {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-access-token":
            "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7ImlkIjoiY29tdW5pY2FuIn0sImlhdCI6MTU5NDQxNzQ1M30.WSWIJsVepunt6oVW-lywb_gw_ABRimLAQRRYxG1YzTM",
        },
      })
        .then((res) => res.json())
        .then((data) => {
          alert("Comentario modificado");
          getComments();
        })
        .catch((err) => console.error("Entró al error ", err));
    }
  };

  const getCurrentDate = (date) => {
    return new Date(Date.parse(date)-1000*60*60*5).toISOString();
  }

  const getStateIcon = (state) =>{
        let renderIcon = null;
        if (state) {
            renderIcon=(<i className="material-icons notranslate">delete</i>);
        } else {
            renderIcon=(<i className="material-icons notranslate">undo</i>);
        }
        return renderIcon;
    }

    const { onDownload } = useDownloadExcel({
      currentTableRef: tableRef.current,
      filename: 'Reporte Comentarios',
      sheet: 'Pagina 1'
    });

  return (
    <div className="page">     
      <div className="container" >
        <h2>Artículos</h2>
        <form onSubmit={getComments}>
          <div className="row">
            <div className="col s12">
              Inserte el dato del artículo:
              <div className="input-field inline">
                <input style={{ width: "100%" }}
                  id="canonical_url"
                  name="canonical"
                  onChange={(e) => setCanonical(e.target.value)}
                  type="text"
                  className="validate"
                  value={canonical}
                />
              </div>
              <button type="submit" style={{ margin: "4px" }} name="action">
                <i className="material-icons center notranslate">search</i>
              </button>
              <button id="exportButton" className="material-icons center notranslate" onClick={onDownload}>cloud_download</button>
            </div>
          </div>
        </form>
      </div>
      <table className="striped" id="canonicalResults"  ref={tableRef}>
        <thead>
          <tr>
            <th>Número</th>
            <th>Comentario</th>
            <th>Estado</th>
            <th>Fecha de creación</th>
            <th>UID</th>
            <th>Fecha de Actualización</th>
            <th>Modificador</th>
            {
              userData.user && userData.user.role === 'administrador' ?
                <th>Cambiar Estado</th> :
                <th></th>
            }
          </tr>
        </thead>
        <tbody>
          {comments.map((comment, index) => {
            return (
              <tr key={comment._id}>
                <td>{index + 1}</td>
                <td>
                  <p style={{ width: "300px", overflowWrap: "break-word" }}>
                    {comment.content}
                  </p>
                  <p style={{ width: "250px", overflowWrap: "break-word" }}>
                    <strong>Autor: </strong>
                    {`${comment.firstName} - ${comment.lastName} - ${(comment.userName)}`}
                  </p>
                </td>
                <td>
                  <p style={{ width: "70px" }}>
                    {comment.status ? "No borrado" : "borrado"}
                  </p>
                </td>
                <td>
                  <p style={{ width: "200px" }}>
                    {getCurrentDate(comment.created)}
                  </p>
                </td>
                <td>
                  <p style={{ width: "150px" }}>{comment.userId}</p>
                </td>
                <td>
                  <p style={{ width: "200px" }}>
                    {comment.updated
                      ? getCurrentDate(comment.updated)
                      : ""}
                  </p>
                </td>
                <td>
                  <p style={{ width: "100px" }}>{comment.adminUpdate}</p>
                </td>
                {
                  userData.user && userData.user.role === 'administrador' ?
                    <td>
                      <button
                        className="btn blue-grey lighten-2"
                        style={{ margin: "4px" }}
                        onClick={() => editComment(comment._id)}
                      >
                        {getStateIcon(comment.status)}
                      </button>
                    </td> :
                    <td></td>
                }
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}