import React, { useEffect, useState, useContext, useRef} from "react";
import { useNavigate } from "react-router-dom";
import UserContext from "../../context/UserContext";
import { useDownloadExcel } from "react-export-table-to-excel";

import Axios from "axios";
import ErrorNotice from "../misc/ErrorNotice";

export default function Author() {
  const [authorid, setAuthorid] = useState();
  const [authorname, setAuthorname] = useState();
  const [authors, setAuthors] = useState([]);
  const [keyword, setKeyword] = useState();
  const [error, setError] = useState();

  const tableRef = useRef(null);

  const { userData } = useContext(UserContext);
  const history = useNavigate();

  useEffect(() => {
    if (localStorage.getItem("auth-token") === '') history('/admin/login');
    fetchAuthors();
  }, []);

  const fetchAuthors = async () => {
    // eslint-disable-next-line no-lone-blocks
    {
      fetch(`${process.env.REACT_APP_BACK_DOMAIN}/authors/list`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-access-token":
            "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7ImlkIjoiY29tdW5pY2FuIn0sImlhdCI6MTU5NDQxNzQ1M30.WSWIJsVepunt6oVW-lywb_gw_ABRimLAQRRYxG1YzTM",
        },
      })
        .then((res) => res.json())
        .then((data) => {
          setAuthors(data);
        });
    }
  };

  const addAuthor = async (e) => {
    e.preventDefault();
    if (keyword) {
      try {
        const headers = {
          headers: {
            "x-access-token":
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7ImlkIjoiY29tdW5pY2FuIn0sImlhdCI6MTU5NzQyNzYyNX0.I83CKLpdLeFpWQRIsHeMPyb5R0UvNlqLUgQlGvREOQA",
          },
        };
        const getAuthors = await Axios.get(
          `${process.env.REACT_APP_BACK_DOMAIN}/authors/search/${keyword}`,
          headers
        );
        setAuthors(getAuthors.data);
      } catch (err) {
        err.response.data.msg && setError(err.response.data.msg);
      }
    } else if (authorid && authorname) {
      try {
        const headers = {
          headers: {
            "Content-Type": "application/json",
            "x-access-token":
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7ImlkIjoiY29tdW5pY2FuIn0sImlhdCI6MTU5NzQyNzYyNX0.I83CKLpdLeFpWQRIsHeMPyb5R0UvNlqLUgQlGvREOQA",
          },
        };

        const newAuthor = {
          authorId: authorid,
          authorName: authorname,
          creator: userData.user.displayName,
        };
        await Axios.post(`${process.env.REACT_APP_BACK_DOMAIN}/authors/add`, newAuthor, headers);
        fetchAuthors();
      } catch (err) {
        err.response.data.msg && setError(err.response.data.msg);
      }
    }
  };

  const editAuthor = (id = false) => {
    try {
      // eslint-disable-next-line no-restricted-globals
      if (confirm("¿Desea cambiar el estado de este autor?")) {
        fetch(`${process.env.REACT_APP_BACK_DOMAIN}/authors/${id}/${userData.user.displayName}`, {
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-access-token":
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7ImlkIjoiY29tdW5pY2FuIn0sImlhdCI6MTU5NDQxNzQ1M30.WSWIJsVepunt6oVW-lywb_gw_ABRimLAQRRYxG1YzTM",
          },
        })
          .then((res) => res.json())
          .then((data) => {
            alert("Palabra modificada");
            fetchAuthors();
          })
          .catch((err) => console.error("Entró al error ", err));
      }
    } catch (err) {
      err.response.data.msg && setError(err.response.data.msg);
    }
  };

  const getCurrentDate = (date) => {
    return new Date(Date.parse(date) - 1000 * 60 * 60 * 5).toISOString();
  };

  const getStateIcon = (state) => {
    let renderIcon = null;
    if (state) {
      renderIcon = <i className="material-icons notranslate">delete</i>;
    } else {
      renderIcon = <i className="material-icons notranslate">undo</i>;
    }
    return renderIcon;
  };

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: 'Reporte Autores',
    sheet: 'Pagina 1'
  });

  return (
    <div className="page">
      <div className="container">
        <h2>Autores</h2>
        {error && (
          <ErrorNotice message={error} clearError={() => setError(undefined)} />
        )}
        <form onSubmit={addAuthor}>
          <div className="row">
            <div className="col s12">

              {
                userData.user && (userData.user.role === 'administrador' || userData.user.role === 'lista-negra') ?
                  <div>
                    Insertar el id del autor:
                    <div className="input-field inline">
                      <input
                        id="author_id"
                        name="authorid"
                        onChange={(e) => setAuthorid(e.target.value)}
                        type="text"
                        value={authorid}
                      />
                    </div>
                    Insertar el nombre del autor:
                    <div className="input-field inline">
                      <input
                        id="author_name"
                        name="authorname"
                        onChange={(e) => setAuthorname(e.target.value)}
                        type="text"
                        value={authorname}
                      />
                    </div>
                    <button type="submit" style={{ margin: "4px" }} name="action">
                      <i className="material-icons center notranslate">add</i>
                    </button>
                  </div> :
                  <div></div>
              }
              <div>
                Escriba el autor a buscar:
                <div className="input-field inline">
                  <input
                    id="key_word"
                    name="key_word"
                    onChange={(e) => setKeyword(e.target.value)}
                    type="text"
                  />
                </div>
              </div>
              <button id="exportButton" className="material-icons center notranslate" onClick={onDownload}>cloud_download</button>
            </div>
          </div>
        </form>
      </div>

      <table className="striped" id="authorsReport"  ref={tableRef}>
        <thead>
          <tr>
            <th>Número</th>
            <th>Id Autor</th>
            <th>Nombre Autor</th>
            <th>Estado</th>
            <th>Fecha de creación</th>
            <th>Creador</th>
            <th>Fecha de Actualización</th>
            <th>Modificador</th>
            {
              // eslint-disable-next-line no-mixed-operators
              userData.user && userData.user.role === 'administrador' || userData.user.role === 'lista-negra' ?
                <th>Cambiar Estado</th> :
                <th></th>
            }
          </tr>
        </thead>
        <tbody>
          {authors.map((author, index) => {
            if (author.authorId.includes(keyword) || author.authorName.includes(keyword) || keyword === undefined) {
              return (
                <tr key={author._id}>
                  <td>{index + 1}</td>
                  <td>
                    <p style={{ width: "150px" }}>{author.authorId}</p>
                  </td>
                  <td>
                    <p style={{ width: "200px" }}>{author.authorName}</p>
                  </td>
                  <td>
                    <p style={{ width: "100px" }}>
                      {author.status ? "Activa" : "Inactiva"}
                    </p>
                  </td>
                  <td>
                    <p style={{ width: "200px" }}>
                      {author.created ? getCurrentDate(author.created) : ""}
                    </p>
                  </td>
                  <td>
                    <p style={{ width: "200px" }}>{author.creator}</p>
                  </td>
                  <td>
                    <p style={{ width: "200px" }}>
                      {author.updated ? getCurrentDate(author.updated) : ""}
                    </p>
                  </td>
                  <td>
                    <p style={{ width: "200px" }}>{author.modificator}</p>
                  </td>
                  {

                    // eslint-disable-next-line no-mixed-operators
                    userData.user && userData.user.role === 'administrador' || userData.user.role === 'lista-negra' ?
                      <td>
                        <button
                          className="btn"
                          style={{ margin: "4px" }}
                          onClick={() => editAuthor(author._id)}
                        >
                          {getStateIcon(author.status)}
                        </button>
                      </td> :
                      <td></td>
                  }
                </tr>
              );
            } else {
              return null;
            }
          })}
        </tbody>
      </table>
    </div>
  );
}
