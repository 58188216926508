import React, { useEffect, useState, useContext, useRef } from "react";
import { useNavigate } from "react-router-dom";
import UserContext from "../../context/UserContext";
import { useDownloadExcel } from "react-export-table-to-excel";

import Axios from "axios";
import ErrorNotice from "../misc/ErrorNotice";

export default function Blacklist() {
  const [badword, setBadword] = useState();
  const [badwords, setBadwords] = useState([]);
  const [keyword, setKeyword] = useState();
  const [error, setError] = useState();

  const { userData } = useContext(UserContext);
  const history = useNavigate();

  const tableRef = useRef(null);

  useEffect(() => {
    if (localStorage.getItem("auth-token") === '') history('/admin/login');
    fetchBadWords();
  }, []);

  const fetchBadWords = async () => {
    {
      fetch(`${process.env.REACT_APP_BACK_DOMAIN}/badwords`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-access-token":
            "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7ImlkIjoiY29tdW5pY2FuIn0sImlhdCI6MTU5NDQxNzQ1M30.WSWIJsVepunt6oVW-lywb_gw_ABRimLAQRRYxG1YzTM",
        },
      })
        .then((res) => res.json())
        .then((data) => {
          setBadwords(data);
        });
    }
  };

  const addBadWord = async (e) => {
    e.preventDefault();
    if (keyword) {
      try {
        const headers = {
          headers: {
            "x-access-token":
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7ImlkIjoiY29tdW5pY2FuIn0sImlhdCI6MTU5NzQyNzYyNX0.I83CKLpdLeFpWQRIsHeMPyb5R0UvNlqLUgQlGvREOQA",
          },
        };
        const getBadwords = await Axios.get(
          `${process.env.REACT_APP_BACK_DOMAIN}/search-badword/${keyword}`,
          headers
        );
        setBadwords(getBadwords.data);
      } catch (err) {
        err.response.data.msg && setError(err.response.data.msg);
      }
    } else if (badword) {
      try {
        const headers = {
          headers: {
            "Content-Type": "application/json",
            "x-access-token":
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7ImlkIjoiY29tdW5pY2FuIn0sImlhdCI6MTU5NzQyNzYyNX0.I83CKLpdLeFpWQRIsHeMPyb5R0UvNlqLUgQlGvREOQA",
          },
        };

        const word = {
          word: badword,
          creator: userData.user.displayName,
        };
        const createBadword = await Axios.post(`${process.env.REACT_APP_BACK_DOMAIN}/add-badword`, word, headers);
        fetchBadWords();
      } catch (err) {
        err.response.data.msg && setError(err.response.data.msg);
      }
    }
  };

  const editBadword = (id = false) => {
    try {
      // eslint-disable-next-line no-restricted-globals
      if (confirm("¿Desea cambiar el estado de esta palabra?")) {
        fetch(`${process.env.REACT_APP_BACK_DOMAIN}/badwords/${id}/${userData.user.displayName}`, {
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-access-token":
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7ImlkIjoiY29tdW5pY2FuIn0sImlhdCI6MTU5NDQxNzQ1M30.WSWIJsVepunt6oVW-lywb_gw_ABRimLAQRRYxG1YzTM",
          },
        })
          .then((res) => res.json())
          .then((data) => {
            alert("Palabra modificada");
            fetchBadWords();
          })
          .catch((err) => console.error("Entró al error ", err));
      }
    } catch (err) {
      err.response.data.msg && setError(err.response.data.msg);
    }
  };

  const getCurrentDate = (date) => {
    return new Date(Date.parse(date) - 1000 * 60 * 60 * 5).toISOString();
  };

  const getStateIcon = (state) => {
    let renderIcon = null;
    if (state) {
      renderIcon = <i className="material-icons notranslate">delete</i>;
    } else {
      renderIcon = <i className="material-icons notranslate">undo</i>;
    }
    return renderIcon;
  };

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: 'Reporte Palabras Inadecuadas',
    sheet: 'Pagina 1'
  });

  return (
    <div className="page">
      <div className="container">
        <h2>Palabra Inadecuadas</h2>
        {error && (
          <ErrorNotice message={error} clearError={() => setError(undefined)} />
        )}
        <form onSubmit={addBadWord}>
          <div className="row">
            <div className="col s12">

              {
                userData.user && (userData.user.role === 'administrador' || userData.user.role === 'lista-negra')?
                <div>
                  Escriba la palabra inadecuada:
                  <div className="input-field inline">
                    <input
                      id="bad_word"
                      name="badword"
                      onChange={(e) => setBadword(e.target.value)}
                      type="text"
                      value={badword}
                    />
                  </div>
                  <button type="submit" style={{ margin: "4px" }} name="action">
                    <i className="material-icons center notranslate">add</i>
                  </button>
                </div>:
                <div></div>
              }
              <div>
                Escriba la palabra inadecuada a buscar:
                <div className="input-field inline">
                  <input
                    id="key_word"
                    name="key_word"
                    onChange={(e) => setKeyword(e.target.value)}
                    type="text"
                  />
                </div>
              </div>
              <button id="exportButton" className="material-icons center notranslate" onClick={onDownload}>cloud_download</button>
            </div>
          </div>
        </form>
      </div>

      <table className="striped" id="badwordsReport"  ref={tableRef}>
        <thead>
          <tr>
            <th>Número</th>
            <th>Palabra</th>
            <th>Estado</th>
            <th>Fecha de creación</th>
            <th>Creador</th>
            <th>Fecha de Actualización</th>
            <th>Modificador</th>
            {
              userData.user && userData.user.role === 'administrador' || userData.user.role === 'lista-negra'?
                <th>Cambiar Estado</th> :
                <th></th>
            }
          </tr>
        </thead>
        <tbody>
          {badwords.map((badword, index) => {
            if (badword.word.includes(keyword) || keyword === undefined) {
              return (
                <tr key={badword._id}>
                  <td>{index + 1}</td>
                  <td>
                    <p style={{ width: "200px" }}>{badword.word}</p>
                  </td>
                  <td>
                    <p style={{ width: "100px" }}>
                      {badword.status ? "Activa" : "Inactiva"}
                    </p>
                  </td>
                  <td>
                    <p style={{ width: "200px" }}>
                      {badword.created ? getCurrentDate(badword.created) : ""}
                    </p>
                  </td>
                  <td>
                    <p style={{ width: "200px" }}>{badword.creator}</p>
                  </td>
                  <td>
                    <p style={{ width: "200px" }}>
                      {badword.updated ? getCurrentDate(badword.updated) : ""}
                    </p>
                  </td>
                  <td>
                    <p style={{ width: "200px" }}>{badword.modificator}</p>
                  </td>
                  {
                    
                    userData.user && userData.user.role === 'administrador' || userData.user.role === 'lista-negra'?
                      <td>
                        <button
                          className="btn"
                          style={{ margin: "4px" }}
                          onClick={() => editBadword(badword._id)}
                        >
                          {getStateIcon(badword.status)}
                        </button>
                      </td> :
                      <td></td>
                  }
                </tr>
              );
            } else {
              return null;
            }
          })}
        </tbody>
      </table>
    </div>
  );
}
