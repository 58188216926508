import "core-js/stable";
import "regenerator-runtime/runtime";

import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Login from './components/auth/Login';
import Register from './components/auth/Register';
import Home from "./components/pages/Home";
import Header from './components/layout/Header';
import Comments from "./components/pages/Comments";
import UserFilter from "./components/pages/ByUser";
import CanonicalFilter from "./components/pages/ByCanonical";
import BlackList from "./components/pages/BlackList";
import Banned from "./components/pages/Banned";
import Author from "./components/pages/Author";
import UpdateUser from "./components/pages/UpdateUser";
import UserContext from './context/UserContext';

import "./style.css";
import Axios from 'axios';

function App() {
  const [userData, setUserData] = useState({
    token: undefined,
    user: undefined,
    role: undefined
  });

  useEffect(() => {
    const checkLoggedIn = async () => {
      let token = localStorage.getItem("auth-token");
      if (!token) {
        localStorage.setItem("auth-token", "");
        token = "";
      }
      try {
        const tokenRes = await Axios.post(
          `${process.env.REACT_APP_BACK_DOMAIN}/users/tokenIsValid`,
          null,
          { headers: { "x-auth-token": token } }
        );
        if (tokenRes.data.valid) {
          const userRes = await Axios.get(`${process.env.REACT_APP_BACK_DOMAIN}/users`, {
            headers: { "x-auth-token": token },
          });
          setUserData({
            token,
            user: userRes.data,
            role: userRes.data.role,
          });
        } else {
          localStorage.setItem("auth-token", "");
          setUserData({ token: undefined, user: undefined, role: undefined });
        }
      } catch (err) {
        console.error("Error al validar el token:", err);
        localStorage.setItem("auth-token", "");
        setUserData({ token: undefined, user: undefined, role: undefined });
      }
    };
  
    checkLoggedIn();
  }, []);

  return (
    <>
      <BrowserRouter>
        <UserContext.Provider value={{ userData, setUserData }}>
          <Header />
          <Routes>
            <Route path="/admin" exact element={<Home />} />
            <Route path="/admin/login" exact element={<Login />} />
            <Route path="/admin/authors" exact element={<Author/>} />
            <Route path="/admin/comments" exact element={<Comments/>} />
            <Route path="/admin/register" exact element={<Register/>} />
            <Route path="/admin/user" exact element={<UserFilter/>} />
            <Route path="/admin/canonical" exact element={<CanonicalFilter/>} />
            <Route path="/admin/blacklist" exact element={<BlackList/>} />
            <Route path="/admin/banned" exact element={<Banned/>} />
            <Route path="/admin/update" exact element={<UpdateUser/>} />
          </Routes>
        </UserContext.Provider >
      </BrowserRouter>
    </>
  );
}

export default App;
