import React, { useEffect, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import UserContext from "../../context/UserContext";

export default function Home() {
    const { userData } = useContext(UserContext);
    const history = useNavigate();

    useEffect(() =>{
        if (localStorage.getItem("auth-token") === '') history('/admin/login');
    })

    return (
        <div className="page">
            <div className="container">
                <h1>Bienvenido al servicio de comentarios</h1>
                {/* <h2>{`${userData.user.displayName}`}</h2> */}
            </div>
        </div>
    )
}
